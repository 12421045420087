import React, { useMemo, useState } from "react";
import { Form, Input, UploadFile, Row, Col, Checkbox } from "antd";
import { useCollection } from "../hooks";
import { CollectionModelType, SelectedItem } from "./../types";
import { useUIContext } from "../contexts/UIContext";
import { MediaDropZone } from "components/Media";
import ApiService from "services/ApiService";
import { imageExtensions } from "sharedConstants";
import { FilesDropZone } from "components/Media";

interface CollectionViewProps {
  selectedItem: SelectedItem;
  children?: React.ReactElement | React.ReactElement[] | null;
}

export const CollectionView = ({
  selectedItem,
  children,
}: CollectionViewProps) => {
  const { updateCollection } = useCollection();
  const { setIsDirty } = useUIContext();
  const [refreshKey, setRefreshKey] = useState(0);
  const collection = selectedItem.item as CollectionModelType;

  const validateFileTypes = useMemo(
    () => ({
      "image/png": { maxSize: 5000000 },
      "image/jpg": { maxSize: 5000000 },
      "image/jpeg": { maxSize: 5000000 },
    }),
    []
  );

  const isImage = (filename: string) => {
    const filenameSplit = filename.split(".");
    return imageExtensions.includes(filenameSplit[filenameSplit.length - 1]);
  };

  const onFinish = async (values: any) => {
    await updateCollection({
      _uuid: collection._uuid,
      id: collection.id,
      name: values.name,
      description: values.description,
      custom_request_message: values.custom_request_message,
      sku: values.sku,
      display_sku: values.display_sku,
    });
    setIsDirty(false);
  };

  return (
    <div className="collection-view">
      <Form
        name="collection-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          name: collection.name,
          description: collection.description,
          custom_request_message: collection.custom_request_message,
          sku: collection.sku,
          display_sku: collection.display_sku,
        }}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: false }]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>

        <p>Thumbnail</p>
        <div>
          <img
            src={
              collection.external_url +
              "/thumbnail_compressed/512.png?rerender=" +
              Date.now()
            }
            alt="Descriptive text"
          />
        </div>
        <>
          <FilesDropZone
            beforeUpload={async (fileData) => {
              try {
                const uploadFormData = new FormData();
                Object.keys(collection.thumbnail_post_url?.fields).forEach(
                  (key) => {
                    uploadFormData.append(
                      key,
                      collection.thumbnail_post_url?.fields[key]
                    );
                  }
                );
                uploadFormData.append("file", fileData);
                // @ts-ignore
                const s3UploadResponse = await ApiService.postToS3(
                  collection.thumbnail_post_url?.url,
                  uploadFormData
                );
                setTimeout(() => {
                  setRefreshKey(refreshKey + 1);
                }, 10000);
                return false;
              } catch (e) {
                console.log(e);
              }
            }}
            fileList={[]}
            listType="picture"
            showUploadList={{ showRemoveIcon: false }}
          />
        </>

        <Form.Item
          label="Custom Request Instructions:"
          name="custom_request_message"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Row gutter={16}>
          <Col span={18}>
            <Form.Item
              label="SKU:"
              name="sku"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Display SKU"
              name="display_sku"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

        {children}
      </Form>
    </div>
  );
};

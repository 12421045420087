import React from "react";
import { Button, Form, Input, Row, Col, Checkbox } from "antd";
import { useItem } from "../hooks";
import { ItemModelType, SelectedItem } from "./../types";
import { useUIContext } from "../contexts/UIContext";

interface ItemViewProps {
  selectedItem: SelectedItem;
  children?: React.ReactElement | React.ReactElement[] | null;
}

export const ItemView = ({ selectedItem, children }: ItemViewProps) => {
  console.log("Selected Item in ItemView:", selectedItem);
  const { updateItem } = useItem();
  const { setIsDirty } = useUIContext();
  const item = selectedItem.item as ItemModelType;

  const onFinish = async (values: any) => {
    await updateItem({
      _uuid: item._uuid,
      id: item.id,
      name: values.name,
      price: values.price,
      dimensions: values.dimensions,
      description: values.description,
      custom_request_message: values.custom_request_message,
      item_text_label: values.item_text_label,
      sku: values.sku,
      display_sku: values.display_sku,
    });
    setIsDirty(false);
  };

  return (
    <div className="item-view">
      <Form
        name="part-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          name: item.name,
          price: item.price || 0,
          dimensions: item.dimensions,
          description: item.description,
          custom_request_message: item.custom_request_message,
          item_text_label: item.item_text_label,
          sku: item.sku,
          display_sku: item.display_sku,
        }}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input the name!" }]}
        >
          <Input />
        </Form.Item>

        {/*<Form.Item
          label="Price"
          name="price"
          rules={[{ required: true, message: "Please input the price!" }]}
        >
          <Input addonBefore="$" type="number" />
      </Form.Item>*/}

        <Form.Item label="Dimensions" name="dimensions">
          <Input />
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item
          label="Custom Request Instructions:"
          name="custom_request_message"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Item Text Label" name="item_text_label">
          <Input />
        </Form.Item>

        <Row gutter={16}>
          <Col span={18}>
            <Form.Item
              label="SKU:"
              name="sku"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Display SKU"
              name="display_sku"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

        {children}
      </Form>
    </div>
  );
};

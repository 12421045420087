import React, { useMemo, useState } from "react";
import { Form, Input, UploadFile, Row, Col, Checkbox } from "antd";
import { ItemVersionModelType, SelectedItem } from "./../types";
import { FilesDropZone } from "components/Media";
import { useItemVersions } from "../hooks";
import { imageExtensions } from "sharedConstants";
import { useUIContext } from "../contexts/UIContext";
import { MediaDropZone } from "components/Media";
import ApiService from "services/ApiService";

interface ItemVersionViewProps {
  selectedItem: SelectedItem;
  children?: React.ReactElement | React.ReactElement[] | null;
}

export const ItemVersionView = ({
  selectedItem,
  children,
}: ItemVersionViewProps) => {
  console.log("Selected Item in ItemVersionView:", selectedItem);
  const { updateItemVersion, addReferenceFiles } = useItemVersions();
  const { setIsDirty } = useUIContext();
  const [refreshKey, setRefreshKey] = useState(0);
  const itemVersion = selectedItem.item as ItemVersionModelType;

  const validateFileTypes = useMemo(
    () => ({
      "image/png": { maxSize: 5000000 },
      "image/jpg": { maxSize: 5000000 },
      "image/jpeg": { maxSize: 5000000 },
    }),
    []
  );

  const isImage = (filename: string) => {
    const filenameSplit = filename.split(".");
    return imageExtensions.includes(filenameSplit[filenameSplit.length - 1]);
  };

  const fileList = useMemo<UploadFile[]>(
    () =>
      itemVersion.reference_file.map((file: any) => ({
        name: `${file.file_name}`,
        uid: file.id,
        thumbUrl: isImage(file.file_name) ? file.url : undefined,
        url: file.url,
      })) || [],
    [itemVersion.reference_file]
  );

  const onFinish = async (values: any) => {
    await updateItemVersion({
      _uuid: itemVersion._uuid,
      id: itemVersion.id,
      base_price: values.base_price,
      custom_request_message: values.custom_request_message,
      sku: values.sku,
      display_sku: values.display_sku,
    });
    setIsDirty(false);
  };

  return (
    <div className="item-version-view">
      <Form
        name="item-version-form"
        layout="vertical"
        style={{ maxWidth: 600 }}
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{
          name: itemVersion.name,
          base_price: itemVersion.base_price || 0,
          custom_request_message: itemVersion.custom_request_message,
          sku: itemVersion.sku,
          display_sku: itemVersion.display_sku,
        }}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Form.Item label="Version" name="name">
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Base Price"
          name="base_price"
          rules={[{ required: true, message: "Please input the base price!" }]}
        >
          <Input addonBefore="$" type="number" />
        </Form.Item>

        <Form.Item
          label="Reference Files"
          //getValueFromEvent={({ urlFileList }) => urlFileList}
          name="reference_files"
          valuePropName="fileList"
        >
          <MediaDropZone
            multiple
            beforeUpload={async (fileData) => {
              await addReferenceFiles({
                itemVersion: itemVersion,
                fileData,
                fileName: fileData.name,
              });
              return false;
            }}
            fileList={fileList}
            accept=".jpg,.jpeg,.png"
            validateFileTypes={validateFileTypes}
            maxCount={10}
          />
        </Form.Item>

        {/* <Form.Item
          label="Reference Files"
          name="referenceFiles"
          valuePropName="fileList"
        >
          <>
            <FilesDropZone
              multiple
              beforeUpload={async (fileData) => {
                await addReferenceFiles({
                  layout: itemVersion,
                  fileData,
                  fileName: fileData.name,
                });
                return false;
              }}
              fileList={fileList}
              listType="picture"
              showUploadList={{ showRemoveIcon: false }}
              // showUploadList={false}
            />
          </>
        </Form.Item> */}

        <p>Thumbnail</p>
        <div>
          <img
            src={
              itemVersion.external_url +
              "/thumbnail_compressed/512.png?rerender=" +
              Date.now()
            }
            alt="Descriptive text"
          />
        </div>
        <>
          <FilesDropZone
            beforeUpload={async (fileData) => {
              try {
                const uploadFormData = new FormData();
                Object.keys(itemVersion.thumbnail_post_url?.fields).forEach(
                  (key) => {
                    uploadFormData.append(
                      key,
                      itemVersion.thumbnail_post_url?.fields[key]
                    );
                  }
                );
                uploadFormData.append("file", fileData);
                // @ts-ignore
                const s3UploadResponse = await ApiService.postToS3(
                  itemVersion.thumbnail_post_url?.url,
                  uploadFormData
                );
                setTimeout(() => {
                  setRefreshKey(refreshKey + 1);
                }, 10000);
                return false;
              } catch (e) {
                console.log(e);
              }
            }}
            fileList={[]}
            listType="picture"
            showUploadList={{ showRemoveIcon: false }}
          />
        </>

        <Form.Item
          label="Custom Request Instructions:"
          name="custom_request_message"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Row gutter={16}>
          <Col span={18}>
            <Form.Item
              label="SKU:"
              name="sku"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Display SKU"
              name="display_sku"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>

        {children}
      </Form>
    </div>
  );
};

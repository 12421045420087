import React, { useCallback } from "react";
import { notification } from "antd";
import { AtLeast, CollectionModelType } from "../types";
import { useUIContext } from "../contexts/UIContext";
import { useCollectionContext } from "../contexts/CollectionContext";
import { ProductsService } from "servicesNew/api";

export const useCollection = () => {
  const { updateItemByUUID } = useCollectionContext();
  const { taskWrapper } = useUIContext();

  const updateCollection = useCallback(
    async (collection: AtLeast<CollectionModelType, "name">) => {
      if (
        typeof collection.id === "number" &&
        typeof collection._uuid === "string"
      ) {
        // Ensure id and _uuid are defined
        await ProductsService.productsItemCollectionsUpdate({
          id: collection.id,
          data: {
            name: collection.name,
            description: collection.description,
            custom_request_message: collection.custom_request_message,
            sku: collection.sku,
            display_sku: collection.display_sku,
          },
        });

        updateItemByUUID(collection._uuid, () => ({
          ...collection,
          name: collection.name,
        }));
        notification.success({
          message: "Collection successfully updated!",
        });
      } else {
        // Handle the case where id or _uuid is undefined
        console.error("Missing collection id or _uuid");
      }
    },
    [updateItemByUUID]
  );

  return {
    updateCollection: taskWrapper(updateCollection),
  };
};

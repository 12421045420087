import React, { useCallback, useState } from "react";
import { notification } from "antd";
import { useParams } from "react-router-dom";
import { useUIContext } from "../contexts/UIContext";
import { useCollectionContext } from "../contexts/CollectionContext";
import { ProductsService } from "servicesNew/api";
import ApiService from "services/ApiService";
import { postToS3 } from "services/file_locker";
import {
  AtLeast,
  PartGroupModelType,
  PartModelType,
  MeshMaterialModelType,
} from "../types";
import { createPart } from "../utils/api";
import { getPartModel, getMeshMaterialModel } from "../utils/dataModel";
import _ from "lodash"; // or 'underscore' if you're using underscore
import { format } from "date-fns";
interface PartGroupAssociationMap {
  [groupId: string]: { partGroupAssociationIds: number[]; partIds: number[] };
}
interface MaterialAssociationData {
  origin_material_id: number;
  material_association_id: number;
  id: any;
}
interface MaterialAssociation {
  id: number;
  material_associations: {
    id: number;
    type: string;
  }[];
}
interface ItemVersion {
  id: number;
  name: string;
  list_order: number;
  childrens: PartGroup[];
}

interface PartGroup {
  id: number;
  name: string;
  list_order: number;
  childrens: Part[];
}

interface Part {
  id: number;
  name: string;
  list_order: number;
  material_associations: MaterialAssociation[];
}

interface MaterialAssociation {
  id: number;
  // Add other necessary properties
}
interface Node {
  id: number;
  childrens?: Node[];
  material_associations?: MaterialAssociation[];
  list_order: number;
}
export const useParts = () => {
  const [materialAssociations, setMaterialAssociations] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const { updateItemByUUID } = useCollectionContext();
  const { taskWrapper } = useUIContext();
  const { companyId, orderId, itemCollectionId } = useParams();

  const companyIdExtracted = companyId !== undefined ? companyId : "";

  const addPart = useCallback(
    async ({
      partGroup,
      name,
    }: {
      partGroup: PartGroupModelType;
      name: string;
    }) => {
      const list_order = partGroup.childrens.length;
      const partModel = await createPart({
        partGroupId: partGroup.id,
        partGroupName: partGroup.name || "Standard Base",
        name,
        list_order,
      });
      updateItemByUUID(partGroup._uuid, (_partGroup) => {
        (_partGroup as PartGroupModelType).childrens.push(partModel);
        return _partGroup;
      });
      notification.success({
        message: "Part successfully created!",
      });
    },
    [updateItemByUUID]
  );

  const updatePart = useCallback(
    async (
      part: AtLeast<
        PartModelType,
        /*"_uuid" | "id" |*/ "name" | "price_mod" | "visible"
      >
    ) => {
      if (typeof part.id === "number" && typeof part._uuid === "string") {
        await ProductsService.productsPartsUpdate({
          id: part.id,
          data: {
            name: part.name,
            price_mod: part.price_mod,
            visible: part.visible,
            sku: part.sku,
            display_sku: part.display_sku,
          },
        });

        updateItemByUUID(part._uuid, () => ({
          ...part,
          name: part.name,
          price_mod: part.price_mod,
          //visible: part.visible,
        }));
        notification.success({
          message: "Part successfully updated!",
        });
      } else {
        console.error("Missing Part id or _uuid");
      }
    },
    [updateItemByUUID]
  );

  /*const updatePart = useCallback(
    async (part: AtLeast<PartModelType, "_uuid" | "id" | "part_group_id">) => {
      await ProductsService.productsPartsUpdate({
        id: part.id,
        data: part,
      });
      updateItemByUUID(part._uuid, () => part);
      notification.success({
        message: "Part successfully updated!",
      });
    },
    [updateItemByUUID]
  );*/

  const clonePart = useCallback(
    async ({
      part,
      partGroup,
      selectedItem,
    }: {
      part: PartModelType;
      partGroup: PartGroupModelType;
      selectedItem: any;
    }) => {
      const selectedItemData = selectedItem?.data?.itemVersion;

      // return;
      // function extractMatchingPartAndGroupIds(
      //   partData: any,
      //   itemVersionData: any
      // ): { partId: number; groupId: number }[] {
      //   if (
      //     !partData.part_association_groups ||
      //     partData.part_association_groups.length === 0
      //   ) {
      //     return [];
      //   }

      //   const partAssociationGroupIds: number[] =
      //     partData.part_association_groups.map((group: any) => group.id);
      //   const matchingPartAndGroupIds: { partId: number; groupId: number }[] =
      //     [];

      //   itemVersionData.childrens.forEach((partGroup: any) => {
      //     partGroup.childrens.forEach((part: any) => {
      //       if (
      //         part.part_association_groups &&
      //         part.part_association_groups.length > 0 &&
      //         part.part_association_groups.some((group: any) =>
      //           partAssociationGroupIds.includes(group.id)
      //         )
      //       ) {
      //         part.part_association_groups.forEach((group: any) => {
      //           if (partAssociationGroupIds.includes(group.id)) {
      //             matchingPartAndGroupIds.push({
      //               partId: part.id,
      //               groupId: group.id,
      //             });
      //           }
      //         });
      //       }
      //     });
      //   });

      //   return matchingPartAndGroupIds;
      // }
      function extractListOrderOfLastChildPart(
        partGroupData: any
      ): number | null {
        const childrens = partGroupData.childrens;

        if (!Array.isArray(childrens) || childrens.length === 0) {
          return null; // No children or invalid data
        }

        const lastChild = childrens[childrens.length - 1];
        return lastChild.list_order ?? null;
      }
      const lastChildListOrder = extractListOrderOfLastChildPart(
        selectedItem?.data?.partGroup
      );

      // Part clone
      const clonedListOrder =
        lastChildListOrder != null ? lastChildListOrder + 1 : null;
      const suffix = format(new Date(), "yyyy-MM-dd HH-mm:ss");
      const partClone = await ProductsService.productsPartsClone({
        data: {
          part_group_id: part.part_group_id,
          name: `${part.name} -- clone ${suffix}`,
          price_mod: part.price_mod,
          visible: part?.visible,
          list_order: clonedListOrder,
        },
      });

      const copyPartThumbnails =
        await ProductsService.productsCopyPartThumbnails({
          data: {
            destination_part_id: partClone.id,
            origin_part_id: part.id,
          },
        });

      // Upload file (thumbnail) to S3

      // Part Association
      async function extractMatchingPartAndGroupIds(
        partData: any,
        itemVersionData: any
      ) {
        // Check if partData has association groups
        if (
          !partData.part_association_groups ||
          partData.part_association_groups.length === 0
        ) {
          console.log("No part association groups to process.");
          return;
        }

        // Extract the IDs of part association groups from partData
        const partAssociationGroupIds = partData.part_association_groups.map(
          (group: any) => group.id
        );

        // Traverse through itemVersionData to find matching parts and associations
        itemVersionData.childrens.forEach((partGroup: any) => {
          partGroup.childrens.forEach(async (part: any) => {
            // Check if the part has association groups
            if (
              part.part_association_groups &&
              part.part_association_groups.length > 0
            ) {
              // Filter the part's association groups to find matches with partData
              const matchingGroups = part.part_association_groups.filter(
                (group: any) => partAssociationGroupIds.includes(group.id)
              );

              // If there are matching groups, send each association to the API
              if (matchingGroups.length > 0) {
                for (const group of matchingGroups) {
                  await sendAssociationToApi(part.id, group.id);
                }
              }
            }
          });
        });
      }

      // Define the function to send a part association to the API
      async function sendAssociationToApi(partId: number, groupId: number) {
        try {
          await ProductsService.productsPartAssociationCreate({
            partId: part?.id === partId ? partClone?.id : partId,
            partAssociationGroupId: groupId,
            data: {
              // Additional data can be included here as needed
              // For example:
              // ui_message: "Your UI message",
              // type: "Your type",
            },
          });
          console.log(
            `Association sent for partId: ${partId}, groupId: ${groupId}`
          );
        } catch (error) {
          console.error(
            `Error sending association for partId: ${partId}, groupId: ${groupId}`,
            error
          );
        }
      }
      const matchingPartIds = extractMatchingPartAndGroupIds(
        part,
        selectedItemData
      );

      const partGroupModel = getPartModel(partClone);
      updateItemByUUID(partGroup._uuid, (_partGroup) => {
        (_partGroup as PartGroupModelType).childrens.push(partGroupModel);
        return {};
      });
      notification.success({
        message: "Part successfully cloned!",
      });
      // return;
      // console.log("partClone", partClone);

      // Material clone
      /*************** Check for dublicates by name *****************/

      const existingMeshMaterials = (part as PartModelType).childrens || [];
      const addedMeshMaterials = await Promise.all(
        part?.childrens.map(async (_meshMaterial, index) => {
          const price_mod = 0;
          const response: MeshMaterialModelType[] =
            await ProductsService.productsMeshMaterialsCopy({
              data: {
                destination_part_id: partClone.id,
                company_id: Number(companyIdExtracted),
                origin_material_ids: [_meshMaterial.id],
                categories: _meshMaterial.material_category_associations,
              },
            });
          // return;
          // Extract the id from the response

          const newMeshMaterialId = response[0].id;
          // Use the newMeshMaterialId in your PATCH request to productsMeshMaterialsUpdate
          const list_order = existingMeshMaterials.length + index;
          //const materialPriceModOverrideDefault = 0;
          const updatedMeshMaterial =
            await ProductsService.productsMeshMaterialsUpdate({
              id: newMeshMaterialId,
              data: {
                list_order: _meshMaterial?.list_order,
                // price_mod: _meshMaterial?.price_mod,
              },
            });

          if (
            typeof _meshMaterial.id === "number" &&
            typeof _meshMaterial._uuid === "string"
          ) {
            if (price_mod > 0) {
              await ProductsService.productsPartMaterialModifierUpdate({
                partId: partClone?.id,
                materialId: updatedMeshMaterial.id,
                data: {
                  name: _meshMaterial.name,
                  price_mod: _meshMaterial?.price_mod,
                },
              });
            }
            await ProductsService.productsMeshMaterialsUpdate({
              id: updatedMeshMaterial.id,
              data: {
                visible: _meshMaterial.visible,
              },
            });

            updateItemByUUID(_meshMaterial._uuid, () => ({
              ..._meshMaterial,
              //..._meshMaterial,
              name: _meshMaterial.name,
              price_mod: _meshMaterial.price_mod,
              visible: _meshMaterial.visible,
            }));
            notification.success({
              message: "Material successfully updated!",
            });
          } else {
            console.error("Missing Material id or _uuid");
          }
          return getMeshMaterialModel(
            updatedMeshMaterial,
            price_mod
            //materialPriceModOverrideDefault
          );
        })
      );

      updateItemByUUID(partGroupModel._uuid, (_part) => {
        (_part as PartModelType).childrens.push(...addedMeshMaterials);
        return _part;
      });
      notification.success({
        message: "Materials successfully added!",
        // description: duplicatesCount
        //   ? `Skipped ${duplicatesCount} ${
        //       duplicatesCount > 1 ? `duplicates` : "duplicate"
        //     }!`
        //   : undefined,
      });
      // window.location.reload();
      // return;
      // MATERIAL ASSOCIATION

      // Function to extract material associations

      // for (const result of resultData) {
      //   try {
      //     // Call the API to create a new material association
      //     await ProductsService.productsMeshMaterialAssociationCreate({
      //       materialId: result.materialId,
      //       materialAssociationGroupId: result.associationId,
      //       data: {
      //         // Pass any additional data needed for the API call
      //         // For example:
      //         // ui_message: meshMaterial.ui_message,
      //         // type: meshMaterial.type,
      //       },
      //     });

      //     console.log(
      //       `Material association created for material ID ${result.materialId}`
      //     );
      //   } catch (error) {
      //     console.error(
      //       `Error creating material association for material ID ${result.materialId}:`,
      //       error
      //     );
      //   }
      // }
      // Function to check if any material_association IDs exist in the specified data structure
      const extractMaterialAssociationIds = (jsonData: any) => {
        const ids: any = [];

        if (jsonData.childrens) {
          // Loop through each child item
          jsonData.childrens.forEach((child: any) => {
            if (child.material_associations) {
              // Loop through material associations of the child
              child.material_associations.forEach((association: any) => {
                ids.push(association.id);
              });
            }
          });
        }

        return ids;
      };
      const extracedMaterialIds = extractMaterialAssociationIds(part);

      const extractMaterialAssociations = (
        data: any,
        targetIds: number[]
      ): Array<{
        materialId: number;
        associationId: number;
        list_order: any;
      }> => {
        const results: Array<{
          materialId: number;
          associationId: number;
          list_order: any;
        }> = [];

        const traverse = (node: any): void => {
          if (node && typeof node === "object") {
            if (Array.isArray(node)) {
              node.forEach((item) => traverse(item));
            } else {
              // Extract material associations from the current node
              if (
                node.material_associations &&
                Array.isArray(node.material_associations)
              ) {
                node.material_associations.forEach((association: any) => {
                  if (targetIds.includes(association.id)) {
                    results.push({
                      materialId: node.id,
                      associationId: association.id,
                      list_order: node.list_order,
                    });
                  }
                });
              }

              // Traverse into childrens recursively
              if (node.childrens && Array.isArray(node.childrens)) {
                node.childrens.forEach((child: any) => traverse(child));
              }
            }
          }
        };

        traverse(data);
        return results;
      };
      const resultData = extractMaterialAssociations(
        selectedItemData,
        extracedMaterialIds
      );

      const checkMaterialIdsExist = (
        data: any,
        targetMaterialIds: number[]
      ): boolean => {
        let materialIdsExist = false;

        const traverse = (node: any): void => {
          if (node && typeof node === "object") {
            if (Array.isArray(node)) {
              node.forEach((item) => traverse(item));
            } else {
              if (
                node.origin_material_ids &&
                Array.isArray(node.origin_material_ids)
              ) {
                const existingMaterialIds = node.origin_material_ids.map(
                  (id: number) => id
                );

                // Check if any target material ID exists in the current node
                if (
                  existingMaterialIds.some((id: any) =>
                    targetMaterialIds.includes(id)
                  )
                ) {
                  materialIdsExist = true;
                }
              }
              if (
                !materialIdsExist &&
                node.childrens &&
                Array.isArray(node.childrens)
              ) {
                node.childrens.forEach((child: any) => traverse(child));
              }
            }
          }
        };

        traverse(data);
        return materialIdsExist;
      };

      for (const result of resultData) {
        const { materialId, associationId } = result;

        // Check if the materialId exists in the specified data structure
        const materialExists = checkMaterialIdsExist(part, [materialId]);

        const matchingMeshMaterial = addedMeshMaterials.find(
          (meshMaterial: any) => meshMaterial.list_order === result.list_order
        );

        try {
          if (materialExists && matchingMeshMaterial) {
            // If material ID exists in specified structure, send '1' to the API
            await ProductsService.productsMeshMaterialAssociationCreate({
              materialId: matchingMeshMaterial.id,
              materialAssociationGroupId: associationId,
              data: {
                // Pass any additional data needed for the API call
              },
            });

            console.log(
              `Sent '1' to the API for existing material ID ${materialId}`
            );
          } else {
            // If material ID doesn't exist in specified structure, send the actual material ID to the API
            await ProductsService.productsMeshMaterialAssociationCreate({
              materialId,
              materialAssociationGroupId: associationId,
              data: {
                // Pass any additional data needed for the API call
              },
            });

            console.log(`Sent material ID ${materialId} to the API`);
          }
        } catch (error) {
          console.error(
            `Error sending material ID ${materialId} to the API:`,
            error
          );
        }
      }
      window.location.reload();
    },

    [updateItemByUUID]
  );

  const removePart = useCallback(
    async ({ part }: { part: PartModelType }) => {
      console.log("part", part);

      // For meshMaterial delete
      if (part?.childrens.length != 0) {
        const meshMaterials = part?.childrens;
        console.log("meshMaterialArray", meshMaterials);

        // for maeshmaterial asscociation delete
        const extractIdsFromMaterialAssociations = (
          meshMaterials: Array<any>
        ): Array<number> => {
          // Initialize an empty array to hold the extracted IDs
          const extractedIds: Array<number> = [];

          // Loop through each mesh material in the array
          meshMaterials.forEach((meshMaterial) => {
            // Check if the material_associations array is present and is not empty
            if (
              meshMaterial.material_associations &&
              Array.isArray(meshMaterial.material_associations) &&
              meshMaterial.material_associations.length > 0
            ) {
              // Map through the material_associations array and extract the 'id' from each association
              const ids = meshMaterial.material_associations.map(
                (association: any) => association.id
              );

              // Add the extracted IDs to the extractedIds array
              extractedIds.push(...ids);
            }
          });

          // Return the array of extracted IDs
          return extractedIds;
        };
        const ids = extractIdsFromMaterialAssociations(meshMaterials);
        // Api call for part modifier delete if present
        for (const meshMaterial of meshMaterials) {
          // Logic for material_category_association delete
          if (
            meshMaterial.material_category_associations &&
            meshMaterial.material_category_associations.length > 0
          ) {
            await ProductsService.productsMaterialCategoryAssociationsDelete({
              categoryId: meshMaterial.material_category_associations[0].id,
              materialId: meshMaterial.id,
            });
          }
          if (meshMaterial.price_mod > 0) {
            // Call the API if price_mod is greater than 0
            await ProductsService.productsPartMaterialModifierDelete({
              partId: part.id,
              materialId: meshMaterial.id,
            });
          }
        }
        // API call for mesh material association delete using the extracted IDs
        for (const id of ids) {
          for (const meshMaterial of meshMaterials) {
            // Check if the ID is present in the material_associations array
            if (
              meshMaterial.material_associations &&
              meshMaterial.material_associations.some(
                (association: any) => association.id === id
              )
            ) {
              // Call the API to delete the association
              await ProductsService.productsMeshMaterialAssociationDelete({
                material_id: meshMaterial.id,
                material_association_group_id: id,
              });
            }
          }
        }
        // API call to delete mesh materials and update the part model
        for (const meshMaterial of meshMaterials) {
          // Delete the mesh material using the API
          await ProductsService.productsMeshMaterialsDelete({
            id: meshMaterial.id,
          });

          // Update the part model by removing the deleted material from the childrens array
          updateItemByUUID(meshMaterial._uuid, (_meshMaterial, _part) => {
            (_part as PartModelType).childrens = (
              _part as PartModelType
            ).childrens.filter(({ _uuid }) => _uuid !== meshMaterial._uuid);
            return {};
          });
        }
      }
      //  for part association
      function extractPartAssociationIds(part: any) {
        // Check if the part has part_association_groups and it is an array
        if (
          part.part_association_groups &&
          Array.isArray(part.part_association_groups)
        ) {
          // Extract IDs from each part association group
          const partAssociationIds = part.part_association_groups.map(
            (group: any) => group.id
          );
          return partAssociationIds;
        }
        // Return an empty array if part_association_groups is not present or not an array
        return [];
      }

      // Logic for material_associations delete
      if (
        part.part_association_groups &&
        part.part_association_groups.length > 0
      ) {
        const ids = extractPartAssociationIds(part);

        for (const id of ids) {
          await ProductsService.productsPartAssociationDelete({
            part_id: part.id,
            part_association_group_id: id,
          });
        }
      }

      // return;
      await ProductsService.productsPartsDelete({
        id: part.id,
      });
      updateItemByUUID(part._uuid, (_part, _partGroup) => {
        (_partGroup as PartGroupModelType).childrens = (
          _partGroup as PartGroupModelType
        ).childrens.filter(({ _uuid }) => _uuid !== part._uuid);
        return {};
      });
      notification.success({
        message: "Part successfully removed!",
      });
    },
    [updateItemByUUID]
  );

  const createPartAssociationGroup = useCallback(
    async (
      part: AtLeast<PartModelType, "ui_message" | "type" | "item_version_id">
    ) => {
      if (typeof part.id === "number" && typeof part._uuid === "string") {
        const response =
          await ProductsService.productsPartAssociationGroupCreate({
            data: {
              ui_message: part.ui_message,
              type: part.type,
              item_version_id: part.item_version_id,
            },
          });
        updateItemByUUID(part._uuid, () => ({
          ...part,
          ui_message: part.ui_message,
          type: part.type,
        }));
        notification.success({
          message: "Part Association Group successfully created!",
        });
        return response;
      } else {
        console.error("Invalid Part Association Group");
      }
    },
    [updateItemByUUID]
  );

  const createPartAssociation = useCallback(
    async (
      //partData: PartModelType,
      part: AtLeast<
        PartModelType,
        /*"_uuid" | "id" | "ui_message" | "type" | "part_id"
        |*/ "part_association_group_id"
      >
    ) => {
      if (typeof part.id === "number" && typeof part._uuid === "string") {
        const assocResponse =
          await ProductsService.productsPartAssociationCreate({
            partId: part.id,
            partAssociationGroupId: part.part_association_group_id,
            data: {
              //ui_message: part.ui_message,
              //type: part.type,
            },
          });
        updateItemByUUID(part._uuid, () => ({
          ...part,
          partAssociationGroupId: part.part_association_group_id,
          partId: part.id,
          ui_message: part.ui_message,
          type: part.type,
          //updateItemByUUID(part._uuid, (_part) => {
          //  (_part as PartModelType).childrens.push(...assocResponse);
          //  return _part;
          //});
        }));
        notification.success({
          message: "Part Association successfully created!",
        });
        return getPartModel(part);
      } else {
        console.error("Invalid Part Association");
      }
    },
    [updateItemByUUID]
  );

  /*
  const createPartAssociation = useCallback(
    async ({
      partAssociation,
      name,
    }: {
      partAssociation: PartAssociationModelType;
      name: string;
    }) => {
      const PartAssociationModel = await createPartAssociation({
        partAssociationId: partAssociation.id,
        partAssociationName: partAssociation.name || "Standard Base",
        name,
      });
      updateItemByUUID(partAssociation._uuid, (_partAssociation) => {
        (_partAssociation as PartAssociationModelType).childrens.push(partAssociationModel);
        return _partAssociation;
      });
      notification.success({
        message: "PartAssociation successfully created!",
      });
    },
    [updateItemByUUID]
  );

  const modifyPartAssociation = useCallback(
    async (
      partAssociation: AtLeast<PartAssociationModelType, "name" | "price_mod">
    ) => {
      if (typeof partAssociation.id === "number" && typeof partAssociation._uuid === "string") {
        await ProductsService.productsPartAssociationsUpdate({
          id: partAssociation.id,
          data: { name: partAssociation.name, price_mod: partAssociation.price_mod },
        });

        updateItemByUUID(partAssociation._uuid, () => ({
          ...partAssociation,
          name: partAssociation.name,
          price_mod: partAssociation.price_mod,
        }));
        notification.success({
          message: "PartAssociation successfully modified!",
        });
      } else {
        console.error("Missing PartAssociation id or _uuid");
      }
    },
    [updateItemByUUID]
  );

  const deletePartAssociation = useCallback(
    async ({ partAssociation }: { partAssociation: PartAssociationModelType }) => {
      await ProductsService.productsPartAssociationsDelete({
        id: partAssociation.id,
      });
      updateItemByUUID(partAssociation._uuid, (_partAssociation, _partAssociation) => {
        (_partAssociation as PartAssociationModelType).childrens = (
          _partAssociation as PartAssociationModelType
        ).childrens.filter(({ _uuid }) => _uuid !== partAssociation._uuid);
        return {};
      });
      notification.success({
        message: "PartAssociation successfully deleted!",
      });
    },
    [updateItemByUUID]
  );
  */

  return {
    addPart: taskWrapper(addPart),
    updatePart: taskWrapper(updatePart),
    clonePart: taskWrapper(clonePart),
    removePart: taskWrapper(removePart),
    createPartAssociationGroup: taskWrapper(createPartAssociationGroup),
    createPartAssociation: taskWrapper(createPartAssociation),
    //modifyPartAssociation: taskWrapper(modifyPartAssociation),
    //deletePartAssociation: taskWrapper(deletePartAssociation),
  };
};
